import styled from "styled-components";

import marmore from "../images/marmore.jpg";

const MEDIA = "@media only screen and (max-width: 900px)";

export const Container = styled.div`
  width: 100%;
  background-image: url(${marmore});
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const PostContainer = styled.article`
  padding: 50px 100px;
  border-radius: 10px;
  max-width: 1000px;

  & > section {
    background-color: white;
    padding: 40px;
  }

  ${MEDIA} {
    margin-top: 60px;
    padding: 0;
    width: 100%;
  }
`;

export const PostHeader = styled.header`
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  width: 100%;
  display: flex;
  padding: 20px;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 40px;

  & > h1 {
    font-size: 40px;

    ${MEDIA} {
      font-size: 22px;
    }
  }

  & > p {
    font-size: 26px;

    ${MEDIA} {
      font-size: 15px;
    }
  }
`;

export const BlogHeader = styled.div`
  width: 100%;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url(${marmore});
  background-size: cover;
  border-bottom: solid 2px #c90225;

  ${MEDIA} {
    flex-direction: column;
    text-align: center;
  }
`;

export const BlogImage = styled.img`
  width: 200px;
  margin-right: 50px;

  ${MEDIA} {
    width: 150px;
    margin: 0;
  }
`;

export const BlogTitle = styled.h1`
  text-align: center;
  font-size: 80px;
  font-weight: bold;

  ${MEDIA} {
    font-size: 32px;
  }
`;

export const PostList = styled.section`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  background-color: #dcdee2;
  padding: 80px 50px 10px 50px;

  ${MEDIA} {
    grid-template-columns: 1fr;
    padding: 30px 5px;
  }
`;
