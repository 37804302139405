import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Contato from "../components/Contato";

import { Container, PostContainer, PostHeader } from "./styles";

const BlogPostTemplate = ({ data }) => {
  const post = data.markdownRemark;

  return (
    <Layout>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />
      <Container>
        <PostContainer>
          <PostHeader>
            <h1
              style={{
                marginTop: "20px",
                marginBottom: 0,
              }}
            >
              {post.frontmatter.title}
            </h1>
            <p
              style={{
                marginBottom: "10px",
              }}
            >
              {post.frontmatter.date}
            </p>
            <section style={{ fontStyle: "italic" }}>
              {post.frontmatter.description}
            </section>
          </PostHeader>
          <section dangerouslySetInnerHTML={{ __html: post.html }} />
        </PostContainer>
      </Container>
      <Contato />
    </Layout>
  );
};

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt
      html
      frontmatter {
        title
        date(formatString: "DD/MM/YYYY")
        description
      }
    }
  }
`;
